import React, { Suspense, useEffect } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'
import ChangeLanguage from './pages/changeLanguage'
import BookRidePage from './pages/bookRide'
import SecurityPage from './pages/security'
import socket from 'src/socket'
import { getToken } from 'firebase/messaging'
import { messaging } from './utils/firebase.utils'

const HomePage = React.lazy(() => import('./pages/home'))
const LoginPage = React.lazy(() => import('./pages/login'))
const SignUpPage = React.lazy(() => import('./pages/register'))
const VerifyOtpPage = React.lazy(() => import('./pages/verifyOtp'))
const ProfilePage = React.lazy(() => import('./pages/profile'))
const SettingsPage = React.lazy(() => import('./pages/settings'))
const OrderPage = React.lazy(() => import('./pages/order'))
const CreateOrderPage = React.lazy(() => import('./pages/createOrder'))
const PaymentPage = React.lazy(() => import('./pages/payment'))
const OrderDetailsPage = React.lazy(() => import('./pages/orderDetails'))
const OrderOtpPage = React.lazy(() => import('./pages/orderOtp'))
const CreateMpinPage = React.lazy(() => import('./pages/createMpin'))
const CheckMpinPage = React.lazy(() => import('./pages/checkMpin'))
const BusinessAccountPage = React.lazy(() => import('./pages/businessAccount'))
const PaymentMethodPage = React.lazy(() => import('./pages/paymentMethod'))
const PaymentCompletedPage = React.lazy(() => import('./pages/paymentCompleted'))
const SelectBusinessPlanPage = React.lazy(() => import('./pages/selectBusinessPlan'))
const FindDriver = React.lazy(() => import('./pages/findDriver'))
const RidesPage = React.lazy(() => import('./pages/rides'))
const PayNowPage = React.lazy(() => import('./pages/payNow'))
const FindVehiclepage = React.lazy(() => import('./pages/findVehicle'))
const FeedbackPage = React.lazy(() => import('./pages/feedback'))
// const Camera = React.lazy(() => import('./pages/Camera'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj
  const { VAPID_KEY } = 'BDrq4h-J9SzHDNoEDwhlxdxDnUR8p31n50XJsHhQJAbJSjDvccvRT2'

  async function requestPermission() {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY
      })
        .then(currentToken => {
          if (currentToken) {
            console.log('currentToken', currentToken)
            localStorage.setItem('ZanFCMToken', currentToken)
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.')
            // ...
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err)
          // ...
        })
      console.log('token', token)
    } else if (permission === 'denied') {
      console.log('You denied for the notification')
    }
  }

  useEffect(() => {
    try {
      // requestPermission()
      // Connect to the server
      socket.connect()

      // Handle connection event
      socket.on('connect', () => {
        console.log('Connected to server with custom path')
      })

      // Handle disconnection event
      socket.on('disconnect', () => {
        console.log('Disconnected from server')
      })

      // Clean up on unmount
      return () => {
        // socket.off('connect')
        // socket.off('disconnect')
        // socket.disconnect()
      }
    } catch (error) {
      console.log('error in App.js', error)
    }
  }, [])

  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope)
      // messaging.useServiceWorker(registration)
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error)
    })

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route
            element={
              <BlankLayout>
                <Outlet />
              </BlankLayout>
            }
          >
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />

            <Route
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route
                element={
                  <Guard guestGuard>
                    <Outlet />
                  </Guard>
                }
              >
                <Route path='/' element={<LoginPage />}></Route>
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/register' element={<SignUpPage />}></Route>
                <Route path='/verify-otp' element={<VerifyOtpPage />}></Route>
                <Route path='/create-mpin' element={<CreateMpinPage />}></Route>
                <Route path='/check-mpin' element={<CheckMpinPage />} />
              </Route>
            </Route>
          </Route>

          {/* <Route path='/' element={<Navigate to='/home' replace />} /> */}

          <Route
            element={
              <UserLayout>
                <Outlet />
              </UserLayout>
            }
          >
            <Route
              element={
                <Guard authGuard>
                  <Outlet />
                </Guard>
              }
            >
              <Route path='home' element={<HomePage />} />
              <Route path='book-ride' element={<BookRidePage />} />
              <Route path='find-vehicle/:id' element={<FindVehiclepage />} />
              <Route path='rides/:id' element={<RidesPage />} />
              <Route path='feedback/:id' element={<FeedbackPage />} />
              <Route path='find-driver/:id' element={<FindDriver />} />
              <Route path='pay-now/:id' element={<PayNowPage />} />
              <Route path='order' element={<OrderPage />} />
              <Route path='profile' element={<ProfilePage />} />
              <Route path='profile/settings' element={<SettingsPage />} />
              <Route path='order/create' element={<CreateOrderPage />} />
              <Route path='/changeLanguage' element={<ChangeLanguage />} />
              <Route path='order/payment/:id' element={<PaymentPage />} />
              <Route path='order/order-details' element={<OrderDetailsPage />} />
              <Route path='/order/otp' element={<OrderOtpPage />} />
              <Route path='/profile/business-account' element={<BusinessAccountPage />} />
              <Route path='order/payment-method' element={<PaymentMethodPage />} />
              <Route path='order/payment-completed' element={<PaymentCompletedPage />} />
              <Route path='order/select-business-plan' element={<SelectBusinessPlanPage />} />
              <Route path='profile/security' element={<SecurityPage />} />
              {/* <Route path='order/camera' element={<Camera />} /> */}
            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />
        </Routes>
      </AclGuard>
    </Suspense>
  )
}

export default App
