// ** MUI Imports
import Fab from '@mui/material/Fab'
import AppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import MuiToolbar from '@mui/material/Toolbar'

// ** Icons Imports
import ArrowUp from 'mdi-material-ui/ArrowUp'
import Logo from 'src/assets/images/logo.png'

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Components
import Customizer from 'src/@core/components/customizer'
import Footer from './components/shared-components/footer'
import Navigation from './components/horizontal/navigation'
import ScrollToTop from 'src/@core/components/scroll-to-top'
import AppBarContent from './components/horizontal/app-bar-content'
import Person from 'src/assets/images/person.png'

// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'

// ** Styled Component
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
import { useAuth } from 'src/hooks/useAuth'
import { MEDIA_URL } from 'src/network/endpoints'
// import Avatar from '../components/mui/avatar'
import { Avatar, Typography, Box, Menu, MenuItem, Divider } from '@mui/material'
import Arrow from 'src/assets/images/arrow-right.png'
import { Link, useNavigate } from 'react-router-dom'
import {
  AccountOutline,
  CogOutline,
  CurrencyUsd,
  EmailOutline,
  HelpCircleOutline,
  LogoutVariant,
  MessageOutline
} from 'mdi-material-ui'
import { useEffect, useState } from 'react'
import Translations from 'src/layouts/components/Translations'
import NotificationDropdown from './components/shared-components/NotificationDropdown'

const HorizontalLayoutWrapper = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  ...(themeConfig.horizontalMenuAnimation && { overflow: 'clip' })
})

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(0, 6)} !important`,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const HorizontalLayout = props => {
  // ** Props
  const {
    hidden,
    children,
    settings,
    scrollToTop,
    saveSettings,
    horizontalNavMenuContent: userHorizontalNavMenuContent
  } = props

  const { user, logout } = useAuth()
  const { direction } = settings
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [urlToNavigate, setUrlToNavigate] = useState(null)

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = url => {
    setAnchorEl(null)
    if (url) {
      setUrlToNavigate(url)
    }
  }

  useEffect(() => {
    if (anchorEl === null && urlToNavigate) {
      navigate(urlToNavigate)
      setUrlToNavigate(null)
    }
  }, [anchorEl, urlToNavigate, navigate])

  const handleClickOutside = event => {
    // alert('hi')
    if (anchorEl && !anchorEl.contains(event.target)) {
      // alert('if')
      handleDropdownClose()
    }
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  const handleLogout = () => {
    logout()
    handleDropdownClose()
  }

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside)
  //   }
  // }, [anchorEl])

  // ** Vars
  const { skin, appBar, navHidden, appBarBlur, contentWidth } = settings

  return (
    <HorizontalLayoutWrapper className='layout-wrapper'>
      <AppBar
        color='default'
        elevation={skin === 'bordered' ? 0 : 3}
        className='layout-navbar-and-nav-container'
        position={appBar === 'fixed' ? 'sticky' : 'static'}
        sx={{
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          ...(appBar === 'static' && { zIndex: 13 }),
          backgroundColor: theme => theme.palette.background.paper,
          ...(skin === 'bordered' && { borderBottom: theme => `1px solid ${theme.palette.divider}` }),
          transition: 'border-bottom 0.2s ease-in-out, backdrop-filter .25s ease-in-out, box-shadow .25s ease-in-out',
          ...(appBar === 'fixed'
            ? appBarBlur && {
                backdropFilter: 'blur(8px)',
                backgroundColor: theme => hexToRGBA(theme.palette.background.paper, 0.85)
              }
            : {})
        }}
      >
        {/* <Box
          className='layout-navbar'
          sx={{
            width: '100%',
            ...(navHidden ? {} : { borderBottom: theme => `1px solid ${theme.palette.divider}` })
          }}
        >
          <Toolbar
            className='navbar-content-container'
            sx={{
              mx: 'auto',
              ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } }),
              minHeight: theme => `${theme.mixins.toolbar.minHeight - 1}px !important`
            }}
          >
            <AppBarContent {...props} hidden={hidden} settings={settings} saveSettings={saveSettings} />
          </Toolbar>
        </Box> */}

        {navHidden ? null : (
          <Box
            className='layout-horizontal-nav'
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{ width: '100%', height: '90px', bgcolor: 'primary', borderBottom: '1px solid #656464' }}
            gap={4}
          >
            <Link to='/home' style={{ textDecoration: 'none' }}>
              <img src={Logo} alt='logo' style={{ height: '48px', width: 'auto', paddingLeft: '113px' }} />
            </Link>
            <Toolbar
              className='horizontal-nav-content-container'
              sx={{
                bgcolor: 'primary',
                mx: 'auto',
                height: '100%',
                ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } }),
                minHeight: theme => `${theme.mixins.toolbar.minHeight - (skin === 'bordered' ? 1 : 0)}px !important`
              }}
            >
              {(userHorizontalNavMenuContent && userHorizontalNavMenuContent(props)) || <Navigation {...props} />}
            </Toolbar>
            <NotificationDropdown settings={settings} />
            <Box display='flex' onClick={handleDropdownOpen} flexDirection='row' alignItems='center' gap={3} pr='113px'>
              <Avatar
                alt={user.first_name}
                sx={{ height: '50px', width: '50px', borderRadius: '10px' }}
                src={`${MEDIA_URL}${user.profile_picture}`}
              />
              <Box display='flex' flexDirection='column' minWidth='fit-content'>
                <Box display='flex' flexDirection='row' alignItems='center' gap={2} minWidth='fit-content'>
                  <Typography variant='fm-p1' color='common.white' fontWeight={600} sx={{ textWrap: 'nowrap' }}>
                    {user.first_name} {user.last_name}
                  </Typography>
                  <img src={Arrow} alt='Arrow' style={{ height: '20px', width: 'auto' }} />
                </Box>
              </Box>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleDropdownClose()}
              sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
              anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
            >
              <Box sx={{ pt: 2, pb: 3, px: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {' '}
                      {user.first_name} {user.last_name}
                    </Typography>
                    {/* <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                      Admin
                    </Typography> */}
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ mt: 0, mb: 1 }} />
              <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/profile')}>
                <Box sx={styles}>
                  {/* <AccountOutline sx={{ mr: 2 }} /> */}
                  <img src={Person} alt='Person' style={{ height: '22px', width: '22px', marginRight: 4 }} />
                  <Translations text='Profile' />
                </Box>
              </MenuItem>
              {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/email')}>
                <Box sx={styles}>
                  <EmailOutline sx={{ mr: 2 }} />
                  Inbox
                </Box>
              </MenuItem>
              <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/chat')}>
                <Box sx={styles}>
                  <MessageOutline sx={{ mr: 2 }} />
                  Chat
                </Box>
              </MenuItem>
              <Divider />
              <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/account-settings')}>
                <Box sx={styles}>
                  <CogOutline sx={{ mr: 2 }} />
                  Settings
                </Box>
              </MenuItem>
              <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/pricing')}>
                <Box sx={styles}>
                  <CurrencyUsd sx={{ mr: 2 }} />
                  Pricing
                </Box>
              </MenuItem>
              <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/faq')}>
                <Box sx={styles}>
                  <HelpCircleOutline sx={{ mr: 2 }} />
                  FAQ
                </Box>
              </MenuItem> */}
              {/* <Divider />
              <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
                <LogoutVariant sx={{ mr: 2, fontSize: '1.375rem', color: '#D05C5C' }} />
                Sign out
              </MenuItem> */}
            </Menu>
          </Box>
        )}
      </AppBar>

      <ContentWrapper
        className='layout-page-content'
        sx={{
          ...(contentWidth === 'boxed' && {
            mx: 'auto',
            '@media (min-width:1440px)': { maxWidth: 1440 },
            '@media (min-width:1200px)': { maxWidth: '100%' }
          })
        }}
      >
        {children}
      </ContentWrapper>

      <Footer {...props} />

      <DatePickerWrapper sx={{ zIndex: 11 }}>
        <Box id='react-datepicker-portal'></Box>
      </DatePickerWrapper>

      {themeConfig.disableCustomizer || hidden ? null : <Customizer />}

      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}
    </HorizontalLayoutWrapper>
  )
}

export default HorizontalLayout
