import { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { API_BASE_URL } from './network/endpoints'
const SERVER_URL = 'https://api.zanride.com'

const useSocket = (namespace, rideId) => {
  console.log('namespace, rideId', namespace, rideId)
  const [socket, setSocket] = useState(null)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const socket = io(`${SERVER_URL}/${namespace}`, {
      path: '/findDrivers/',
      transports: ['websocket'],
      reconnectionAttempts: 3,
      timeout: 20000
    })
    console.log('socketsocket', socket)

    socket.on(`OrderRequest_${rideId}`, data => {
      setMessage(data.message)
    })

    socket.on('connect', () => {
      console.log('WebSocket connected.')
    })

    socket.on('connect_error', error => {
      console.error('WebSocket connection error:', error)
    })

    socket.on('disconnect', reason => {
      console.log('WebSocket disconnected:', reason)
    })

    return () => {
      socket.disconnect()
    }
  }, [namespace, rideId])

  return message
}

export default useSocket
