// ** Icon imports
import Home from 'src/assets/images/navbar-home.png'
import Order from 'src/assets/images/navbar-order.png'
import Profile from 'src/assets/images/navbar-profile.png'

const navigation = () => {
  return [
    {
      title: 'Book A Ride',
      icon: Home,
      path: '/book-ride'
    }
    // {
    //   title: 'My Order',
    //   icon: Order,
    //   path: '/order'
    // },
    // {
    //   title: 'My Profile',
    //   icon: Profile,
    //   path: '/profile'
    // }
  ]
}

export default navigation
