import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import Translations from 'src/layouts/components/Translations'
import PhoneInput from 'react-phone-input-2'
import { useAuth } from 'src/hooks/useAuth'
import 'react-phone-input-2/lib/bootstrap.css'
import { ApiEndpoints, MEDIA_URL } from 'src/network/endpoints'
import { FormValidationMessages } from 'src/constants/form.const'
import * as yup from 'yup'
import { axiosInstance } from 'src/network/adapter'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'
import Alert from 'src/assets/images/alert.svg'
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
// import { yupResolver } from '@hookform/resolvers/yup'

function EmergencyContactForm(props) {
  const [loading, setLoading] = useState(false)
  const { operationChange, operationMode, dataToEdit, dataToVerify } = props
  const [phone, setPhone] = useState('')
  const [selectedAddressType, setSelectedAddressType] = useState('home')
  const [phoneDialCode, setPhoneDialCode] = useState('')
  const [isoCode, setIsoCode] = useState('')
  console.log('dataToEdit', dataToEdit)

  const { user, setUser } = useAuth()
  const translation = useCustomTranslation()
  const validationSchema = yup.object().shape({
    full_name: yup.string().trim().required(FormValidationMessages.FULL_NAME.required),
    phone: yup
      .string()
      .required(FormValidationMessages.PHONE.required)
      .matches(FormValidationMessages.PHONE.pattern, FormValidationMessages.PHONE.patternErrorMessage)
  })
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  })
  const handlePhoneChange = (value, country) => {
    setPhone(value)
    setValue('phone', value)
    if (country) {
      clearErrors('phone')
      const { country: countryCode, dialCode, name } = country
      //   console.log('country', country)
      setPhoneDialCode(dialCode)
      setIsoCode(country.countryCode)
    }
  }

  const onSubmit = async data => {
    // setLoading(true);
    const payload = {
      contact_name: data.full_name,
      isoCode: isoCode,
      country_code: '+' + phoneDialCode,
      phone_number: '+' + phone
    }
    console.log('Payload', payload)
    setLoading(true)
    try {
      if (operationMode === 'add') {
        await axiosInstance.post(ApiEndpoints.EMERGENCY_CONTACT.emergency_contact, payload).then(response => {
          //   toast.success(response.data.data.message)
          //   onSuccess()
          operationChange(2, 'add')
          dataToVerify({
            otp: response.data.data.otp,
            country_code: '+' + phoneDialCode,
            phone_number: '+' + phone
          })
          setLoading(false)
        })
      } else {
        await axiosInstance
          .patch(ApiEndpoints.EMERGENCY_CONTACT.change_locations(dataToEdit.id), payload)
          .then(response => {
            console.log('response msg', response.data.message)
            toast.success(response.data.message)
            setLoading(false)
          })
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (operationMode === 'edit') {
      reset({
        type: setSelectedAddressType(dataToEdit.type || ''),
        full_name: dataToEdit.name || '',
        address: dataToEdit.address || '',
        pincode: dataToEdit.pin_code || '',
        mobileNumber: dataToEdit.mobile_number || ''
      })
    }
  }, [reset, user, dataToEdit])

  const [borderColor, setBorderColor] = useState('#474747')

  const handleFocus = () => {
    setBorderColor('#9CE26E')
  }

  const handleBlur = () => {
    setBorderColor('#474747')
  }

  return (
    <Card>
      {/* <CardHeader
        titleTypographyProps={{ style: { fontSize: '22px', fontWeight: 600, color: '#fff' } }}
        title='Emergency'
      /> */}
      <CardContent>
        <Box display='flex' alignItems='center' gap={4} mb={5}>
          <Box
            component={ButtonBase}
            // onClick={() => navigate('/profile')}
            onClick={() => operationChange(0, 'add')}
            border='1px solid #474747'
            borderRadius='8px'
            bgcolor='#202020'
            display='flex'
            p={3}
          >
            <ArrowLeft />
          </Box>
          <Typography fontSize='22px' color='common.white' fontWeight={600}>
            <Translations text='Emergency' />
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box border='1px solid #474747' borderRadius='8px' bgcolor='#202020' p={5}>
            <Grid container direction='column' spacing={4}>
              <Grid item container spacing={6} xs={12}>
                <Grid item xs={12} sm={12}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography sx={{ fontSize: '16px' }} color='common.white' fontWeight={400}>
                      <Translations
                        text={operationMode === 'edit' ? 'Edit Emergency contact' : 'Add Emergency contact'}
                      />
                    </Typography>
                    <img src={Alert} alt='Alert' />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <Controller
                      name='full_name'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          error={Boolean(errors.full_name)}
                          value={value}
                          onChange={onChange}
                          id='full_name'
                          placeholder={translation.translate('form.fullName.placeholder')}
                        />
                      )}
                    />
                    {errors.full_name && (
                      <FormHelperText sx={{ color: 'error.main' }}>
                        <Translations text={`${errors.full_name.message}`} />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <Controller
                      name='phone'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <PhoneInput
                          country={'ng'}
                          error={Boolean(errors.phone)}
                          enableSearch={true}
                          inputStyle={{
                            width: '100%',
                            height: '100%',
                            border: Boolean(errors.phone) ? '1px solid red' : `1px solid ${borderColor}`,
                            // border: `1px solid ${borderColor}`,
                            borderRadius: '8px',
                            color: '#e7e3fcde',
                            boxShadow: 'none',
                            backgroundColor: '#202020'
                          }}
                          containerStyle={{
                            width: '100%'
                          }}
                          dropdownClass='hide-flag'
                          containerClass='hide-flag'
                          //   PhoneInputCountryFlag-borderColor='red'
                          placeholder='Phone number'
                          value={value}
                          onChange={handlePhoneChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                    {errors.phone && (
                      <FormHelperText sx={{ color: 'error.main' }}>
                        <Translations text={`form.phone.error.${errors.phone.message}`} />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid display='flex' flexDirection='row' container spacing={4} xs={4}>
            {operationMode === 'edit' ? (
              <>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start', gap: 2 }}>
                  <LoadingButton
                    sx={{
                      mt: 5,
                      textTransform: 'none',
                      width: '180px',
                      height: '50px',
                      borderRadius: '8px',
                      fontWeight: 600,
                      background: 'primary'
                    }}
                    type='submit'
                    variant='outlined'
                    loading={loading}
                  >
                    <Translations text='Delete' />
                  </LoadingButton>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start', gap: 2 }}>
                  <LoadingButton
                    sx={{
                      mt: 5,
                      textTransform: 'none',
                      width: '180px',
                      height: '50px',
                      borderRadius: '8px',
                      fontWeight: 600,
                      background: 'primary'
                    }}
                    type='submit'
                    variant='contained'
                    loading={loading}
                  >
                    <Translations text='Save' />
                  </LoadingButton>
                </Grid>
              </>
            ) : (
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start', gap: 2 }}>
                <LoadingButton
                  sx={{
                    mt: 5,
                    textTransform: 'none',
                    width: '180px',
                    height: '50px',
                    borderRadius: '8px',
                    fontWeight: 600,
                    background: 'primary'
                  }}
                  type='submit'
                  variant='contained'
                  loading={loading}
                >
                  <Translations text='Add' />
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export default EmergencyContactForm
