import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  FormHelperText,
  ButtonBase,
  Alert
} from '@mui/material'
import Translations from 'src/layouts/components/Translations'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { IconButton } from '@mui/material'
import FundWalletDialog from 'src/views/dialog/FundWalletDialog'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints, GOOGLE_API_KEY } from 'src/network/endpoints'
import { DefaultPaginationSettings } from 'src/constants/general.const'
import { Controller, useForm } from 'react-hook-form'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import { yupResolver } from '@hookform/resolvers/yup'
import Arrow from 'src/assets/images/fromTo.png'
import Person from 'src/assets/images/person.png'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import 'react-datepicker/dist/react-datepicker.css'
import * as yup from 'yup'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import CustomDatePicker from 'src/views/CustomDatepicker'
import toast from 'react-hot-toast'
import Location from 'src/assets/images/geo-location.png'
import axios from 'axios'
import { useGeolocated } from 'react-geolocated'
import { APIProvider, Map } from '@vis.gl/react-google-maps'
import mapStyle from '../../styles/map_style.json'
import CustomMarker from 'src/views/maps/Marker'
import { useAuth } from 'src/hooks/useAuth'
import PayNowAlert from 'src/views/pages/common/payNowAlert'

function BookRidePage() {
  const [formattedDate, setFormattedDate] = useState('')
  const [isopenFundWallet, setIsopenFundWallet] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(DefaultPaginationSettings.ROWS_PER_PAGE)
  const [person, setPerson] = useState(1)
  const [rideId, setRideId] = useState('')
  const [drivers, setDrivers] = useState([])
  const [origin, setOrigin] = useState('')
  const [destination, setDestination] = useState('')
  const { id } = useParams()
  const navigate = useNavigate()
  const translation = useCustomTranslation()
  const [currentLocation, setCurrentLocation] = useState('')
  const [currentLocationDisplay, setCurrentLocationDisplay] = useState('')
  const [useCurrentLocation, setUseCurrentLocation] = useState(false)
  const { state } = useLocation()
  const { stateOrigin, stateDestination } = state || {}
  const { user } = useAuth()
  const [inputValue, setInputValue] = useState(stateDestination || '')
  const validationSchema = yup.object().shape({
    // origin: yup.string().required(FormValidationMessages.ORIGIN.required),
    // destination: yup.string().required(FormValidationMessages.DESTINATION.required)
    // date: yup.string().trim().required(FormValidationMessages.PINCODE.required)
    // numberOfPassengers: yup.string().trim().required(FormValidationMessages.CITY.required)
    // pickupAddress: yup.string().trim().required(FormValidationMessages.ADDRESS.required),
    // deliveryPersonName: yup.string().trim().required(FormValidationMessages.PERSON_NAME.required)
  })
  // deliverypickupPointPincode: yup.string().trim().required(FormValidationMessages.PINCODE.required),
  // deliverypickupPointCity: yup.string().trim().required(FormValidationMessages.CITY.required)

  // deliverypickupPoint: yup.string().trim().required(FormValidationMessages.PICKUP_POINT.required)
  // const styledMapType = new window.google.maps.StyledMapType(
  //   [
  //     // Paste your JSON content here
  //     {
  //       featureType: 'all',
  //       elementType: 'geometry',
  //       stylers: [
  //         {
  //           color: '#202c3e'
  //         }
  //       ]
  //     },
  //     {
  //       featureType: 'all',
  //       elementType: 'labels.text.fill',
  //       stylers: [
  //         {
  //           gamma: 0.01
  //         },
  //         {
  //           lightness: 20
  //         }
  //       ]
  //     }
  //     // Add more styling rules as needed
  //   ],
  //   { name: 'Styled Map' }
  // )
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors, values }
  } = useForm({
    defaultValues: {
      origin: stateOrigin ? stateOrigin : currentLocation ? currentLocation : '',
      destination: stateDestination ? stateDestination : '',
      paymentMethod: 'Cash'
    },
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })

  const fetchCurrentLocation = async (latitude, longitude) => {
    // const coords = localStorage.getItem('coords')
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords?.latitude},${coords?.longitude}&key=AIzaSyCz5jE0upqgbV9IlAt7R8asbRxtC7HBoew`
      )
      if (response.data.status === 'OK') {
        const results = response.data.results[0]
        const formattedAddress = results.formatted_address
        const components = results.address_components
        setCurrentLocation(formattedAddress)
        setCurrentLocationDisplay(formattedAddress)
        // Extract specific components if needed
        let streetName = ''
        for (let component of components) {
          if (component.types.includes('route')) {
            streetName = component.long_name // Get the street name
            break
          }
        }
      }

      // if (response.data.status === 'OK' && response.data.results.length > 0) {
      //   const result = response.data.results[0]
      //   let addressComponents = result.address_components
      //   let street = ''
      //   let locality = ''
      //   let city = ''
      //   let state = ''
      //   let country = ''
      //   let postalCode = ''
      //   addressComponents.forEach(component => {
      //     if (component.types.includes('street_address') || component.types.includes('route')) {
      //       street = component.long_name
      //     } else if (component.types.includes('locality')) {
      //       locality = component.long_name
      //     } else if (component.types.includes('administrative_area_level_1')) {
      //       state = component.long_name
      //     } else if (component.types.includes('country')) {
      //       country = component.long_name
      //     } else if (component.types.includes('postal_code')) {
      //       postalCode = component.long_name
      //     }
      //   })
      //   const fullAddress = `${street}, ${locality}, ${state}`.trim()
      //   setCurrentLocation(fullAddress)
      //   setCurrentLocationDisplay(fullAddress)
      //   return fullAddress !== ', , , , , ' ? fullAddress : 'Unknown Location'
      // } else {
      //   return 'Unknown Location'
      // }

      // setCurrentLocation(response.data.plus_code.compound_code)
      // setCurrentLocationDisplay(response.data.plus_code.compound_code.replace(/^\w{1,5}\+\w{1,5} /, ''))
    } catch (error) {
      // Loader.hide()
      console.error('Error fetching nearby airports:', error)
    }
  }

  const onSubmit = data => {
    let payload = {
      origin: getValues('origin') ? getValues('origin') : useCurrentLocation ? currentLocation : undefined,
      destination: getValues('destination') ? getValues('destination') : stateDestination,
      date: typeof data.date === 'undefined' ? new Date() : data.date,
      numberOfPassengers: person,
      paymentMethod: data.paymentMethod
    }
    axiosInstance
      .post(ApiEndpoints.BOOK_RIDE.book_ride, payload)
      .then(response => {
        setRideId(response.data.data.rides.id)
        navigate(`/rides/${response.data.data.rides.id}`)
        // navigate(`/find-vehicle/${response.data.data.rides.id}`)
        // getDrivers(response.data.data.rides.id)
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
  }

  const handleOriginChange = data => {
    setOrigin(data.label)
  }
  const handleDestinationChange = data => {
    setOrigin(data.label)
  }

  const selectDriver = (driverId, rideId) => {
    axiosInstance.post(ApiEndpoints.DRIVER.select_driver(driverId, rideId)).then(response => {
      navigate(`/find-driver/${rideId}`, {
        state: {
          driverId: driverId,
          rideId: rideId
        }
      })
    })
  }

  const handleRideSelect = driverId => {
    selectDriver(driverId, rideId)
  }

  const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled, positionError } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    userDecisionTimeout: 5000,
    watchLocationPermissionChange: true
  })

  const getAllDriverLocations = () => {
    let payload = {
      lat: coords?.latitude,
      long: coords?.longitude
    }
    axiosInstance
      .post(ApiEndpoints.DRIVER.all_driver_location, payload)
      .then(response => {
        setDrivers(response.data.data.driversInRange)
      })
      .catch(error => {
        console.error('Error fetching drivers:', error)
      })
  }

  useEffect(() => {
    if (coords) {
      fetchCurrentLocation()
      getAllDriverLocations()
    }
    const currentDate = moment().format('Do MMMM, YYYY. dddd')
    setFormattedDate(currentDate)
    // getOrders('onTheway')
    // getOrders('delivered')
  }, [coords])

  // useEffect(() => {
  //   reset({
  //     origin: stateOrigin ? stateOrigin : '',
  //     destination: stateDestination ? stateDestination : ''
  //   })
  // }, [reset, stateDestination, stateOrigin])

  useEffect(() => {
    if (stateDestination) {
      setValue('destination', stateDestination)
    }
    if (useCurrentLocation) {
      setValue('origin', currentLocationDisplay)
    } else {
      // setValue('origin', stateOrigin)
    }
    const newValues = {
      origin: useCurrentLocation ? currentLocationDisplay : stateOrigin || '',
      destination: stateDestination || ''
    }
    // reset(newValues)
  }, [reset, stateOrigin, stateDestination, setValue, useCurrentLocation, currentLocation])

  const handleIncrement = () => {
    const newOriginalPrice = person + 1
    setPerson(newOriginalPrice)
  }

  const handleDecrement = () => {
    if (person > 1) {
      const newOriginalPrice = person - 1
      setPerson(newOriginalPrice)
    }
  }

  const handleManualInput = e => {
    const value = parseFloat(e.target.value)
    if (!isNaN(value)) {
      const newOriginalPrice = value
      setPerson(newOriginalPrice)
    }
  }

  // const [value, setValue] = useState(null) // Initialize state to control the input

  // Function to clear the input
  const clearInput = () => {
    setValue(null)
  }

  const handleCurrentLocationClick = () => {
    setUseCurrentLocation(true)
    clearInput()
    setValue('origin', currentLocationDisplay)
  }

  return (
    <>
      {user?.ongoingRides?.status && <PayNowAlert />}
      <Grid container display='flex' spacing={5} px={{ md: '113px', xs: '10px' }} flexDirection='row'>
        <Grid item xs={12} lg={4} display='flex' gap={5} flexDirection='column'>
          <Card sx={{ bgcolor: '#111111', border: '1px solid #202020' }}>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid id='root-portal' container mb={7}>
                  <Grid item md={2} xs={2}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      pr={5}
                      pt={6}
                      gap={4}
                    >
                      <Typography variant='fm-p2' color='common.white' fontWeight={500}>
                        <Translations text='From' />
                      </Typography>
                      <img src={Arrow} alt='Arrow' style={{ height: '24px', width: '24px' }} />
                      <Typography variant='fm-p2' color='common.white' fontWeight={500}>
                        <Translations text='To' />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={10} xs={10} spacing={3}>
                    <FormControl fullWidth sx={{ mb: 5 }}>
                      <Controller
                        name='origin'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <div style={{ width: '100%' }}>
                            <GooglePlacesAutocomplete
                              apiKey={GOOGLE_API_KEY}
                              selectProps={{
                                defaultInputValue: value,
                                onChange: newValue => {
                                  setValue('origin', newValue?.label)
                                },
                                placeholder: <Translations text='Enter Location' />,
                                value: value ? { label: value, value } : null,
                                // onChange,
                                styles: {
                                  container: provided => ({
                                    ...provided,
                                    backgroundColor: 'transparent',
                                    width: '100%'
                                    // padding: 4
                                  }),
                                  input: provided => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '16px',
                                    color: '#e7e3fcde',
                                    backgroundColor: 'transparent',
                                    border: '1px solid #474747',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }),
                                  option: provided => ({
                                    ...provided,
                                    color: '#e7e3fcde',
                                    backgroundColor: '#202020'
                                    // padding: '10px'
                                  }),
                                  singleValue: provided => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '16px',
                                    color: '#e7e3fcde',
                                    backgroundColor: 'transparent',
                                    border: '1px solid #474747',
                                    borderRadius: '8px'
                                  }),
                                  control: provided => ({
                                    ...provided,
                                    backgroundColor: 'transparent',
                                    boxShadow: 'red',
                                    border: 'none',
                                    width: '100%',
                                    color: '#e7e3fcde'
                                  }),
                                  menu: provided => ({
                                    ...provided,
                                    zIndex: 9999
                                  })
                                },
                                components: {
                                  IndicatorsContainer: () => null
                                }
                              }}
                            />
                          </div>
                        )}
                      />
                      {errors.origin && (
                        <FormHelperText sx={{ color: 'error.main' }}>
                          <Translations text={`${errors.origin.message}`} />
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <Controller
                        name='destination'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <div style={{ width: '100%' }}>
                            <GooglePlacesAutocomplete
                              apiKey={GOOGLE_API_KEY}
                              selectProps={{
                                defaultInputValue: value, //set default value
                                // onChange: newValue,
                                onChange: newValue => setValue('destination', newValue?.label),
                                placeholder: <Translations text='Enter Location' />,
                                value: value ? { label: value, value } : null,
                                // onChange,
                                styles: {
                                  container: provided => ({
                                    ...provided,
                                    backgroundColor: 'transparent',
                                    width: '100%'
                                    // padding: 4
                                  }),
                                  input: provided => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '16px',
                                    color: '#e7e3fcde',
                                    backgroundColor: 'transparent',
                                    border: '1px solid #474747',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }),
                                  option: provided => ({
                                    ...provided,
                                    color: '#e7e3fcde',
                                    backgroundColor: '#202020'
                                    // padding: '10px'
                                  }),
                                  singleValue: provided => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '16px',
                                    color: '#e7e3fcde',
                                    backgroundColor: 'transparent',
                                    border: '1px solid #474747',
                                    borderRadius: '8px'
                                  }),
                                  control: provided => ({
                                    ...provided,
                                    backgroundColor: 'transparent',
                                    boxShadow: 'red',
                                    border: 'none',
                                    width: '100%',
                                    color: '#e7e3fcde'
                                  }),
                                  menu: provided => ({
                                    ...provided,
                                    zIndex: 9999
                                  })
                                },
                                components: {
                                  IndicatorsContainer: () => null
                                }
                              }}
                            />
                            {/* <GooglePlacesAutocomplete
                              apiKey={GOOGLE_API_KEY}
                              // onLoadFailed={alert('fail')}
                              selectProps={{
                                // onFocus: setValue('destination', null),
                                value: inputValue ? { label: inputValue, value: inputValue } : null, // Ensure correct value format
                                onChange: selected => {
                                  // Update the local state and form value when an option is selected
                                  const newValue = selected ? selected.value : ''
                                  setInputValue(newValue)
                                  onChange(newValue)
                                },
                                onFocus: () => {
                                  // Clear input value when focused
                                  setInputValue('')
                                },
                                defaultInputValue: value, //set default value
                                onChange: selected => {
                                  // Update the form value when an option is selected
                                  onChange(selected ? selected.value : '')
                                  console.log('selected', selected)
                                },
                                // onBlur: () => {
                                //   // Trigger blur to handle form validation if necessary
                                //   onBlur()
                                // },
                                placeholder: stateDestination ? (
                                  stateDestination
                                ) : (
                                  <Translations text='Enter Location' />
                                ),
                                // value: value ? { label: value, value } : null,
                                // onChange,
                                styles: {
                                  container: provided => ({
                                    ...provided,
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                    // padding: 4
                                  }),
                                  input: provided => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '16px',
                                    color: '#e7e3fcde',
                                    backgroundColor: 'transparent',
                                    border: '1px solid #474747',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textWrap: 'nowrap'
                                  }),
                                  option: provided => ({
                                    ...provided,
                                    color: '#e7e3fcde',
                                    backgroundColor: '#202020'
                                    // padding: '10px'
                                  }),
                                  singleValue: provided => ({
                                    ...provided,
                                    padding: 15,
                                    fontSize: '16px',
                                    color: '#e7e3fcde',
                                    backgroundColor: 'transparent',
                                    // border: '1px solid #474747',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textWrap: 'nowrap'
                                  }),
                                  control: provided => ({
                                    ...provided,
                                    backgroundColor: 'transparent',
                                    boxShadow: 'red',
                                    border: 'none',
                                    width: '100%',
                                    color: '#e7e3fcde',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textWrap: 'nowrap'
                                  }),
                                  menu: provided => ({
                                    ...provided,
                                    zIndex: 9999
                                  })
                                },
                                components: {
                                  IndicatorsContainer: () => null
                                }
                              }}
                            /> */}
                          </div>
                        )}
                      />
                      {errors.destination && (
                        <FormHelperText sx={{ color: 'error.main' }}>
                          <Translations text={`${errors.destination.message}`} />
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* <ButtonBase onClick={() => fetchCurrentLocation()}> */}
                {currentLocation && (
                  <Box
                    component={ButtonBase}
                    sx={{ width: '100%' }}
                    onClick={() => {
                      handleCurrentLocationClick()
                    }}
                    display='flex'
                    alignItems='center'
                    textAlign='start'
                    justifyContent='start'
                    mb={7}
                    gap={3}
                  >
                    <IconButton
                      sx={{
                        borderRadius: '50%',
                        backgroundColor: '#202020',
                        width: '56px',
                        height: '56px'
                      }}
                    >
                      <img
                        style={{ width: '30px', height: '30px' }}
                        src={Location}
                        // src={vehicle}
                        alt='Location'
                      />
                    </IconButton>
                    <Box display='flex' flexDirection='column'>
                      <Typography variant='fm-p2' color='primary' fontWeight={500}>
                        <Translations text='Use Current Location' />
                      </Typography>
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 1, // Limit to 2 lines
                          cursor: 'pointer',
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        color='#868588'
                      >
                        <Translations text={currentLocationDisplay} />
                      </Typography>
                    </Box>
                  </Box>
                )}
                {/* </ButtonBase> */}
                <Grid container mb={7} spacing={4}>
                  <Grid item md={6} xs={6}>
                    <Box
                      height='57px'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      border='1px solid #474747'
                      borderRadius='8px'
                      backgroundColor='#202020'
                      overflow='hidden'
                      p={2}
                      gap={2}
                    >
                      <img src={Person} alt='Person' style={{ height: '24px', width: '24px' }} />
                      {/* <ButtonGroup sx={{ width: '220px', height: '55px' }}> */}
                      <Box
                        sx={{
                          // border: '1px solid #474747',
                          width: 'auto',
                          height: '35px'
                        }}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Button
                          sx={{
                            border: '1px solid #474747',
                            borderRadius: '8px',
                            bgcolor: '#202020',
                            color: '#fff',
                            padding: 2,
                            minWidth: 'unset !important',
                            marginRight: '5px'
                          }}
                          variant='contained'
                          onClick={handleDecrement}
                        >
                          <RemoveIcon sx={{ width: '12px' }} />
                        </Button>
                        <TextField
                          value={person}
                          size='small'
                          onChange={handleManualInput}
                          sx={{
                            maxWidth: '40px',
                            bgcolor: '#202020',
                            width: '60px',
                            textAlign: 'end',
                            marginRight: '5px'
                          }} // Adjusted width
                        />
                        <Button
                          sx={{
                            border: '1px solid #474747',
                            borderRadius: '8px',
                            bgcolor: '#202020',
                            color: '#fff',
                            // width: '40px', // Adjusted width
                            padding: 2,
                            minWidth: 'unset !important'
                          }}
                          variant='contained'
                          onClick={handleIncrement}
                        >
                          <AddIcon sx={{ width: '12px' }} />
                        </Button>
                      </Box>

                      {/* </ButtonGroup> */}
                      {/* <Box border='1px solid #474747' borderRadius='8px' backgroundColor='#202020' p={2} px={4}>
                        -
                      </Box>
                      <Box border='1px solid #474747' borderRadius='8px' backgroundColor='#202020' p={2} px={4}>
                        <Typography variant='fm-p3' color='common.white' fontWeight={500}>
                          <Translations text='1' />
                        </Typography>
                      </Box>
                      <Box border='1px solid #474747' borderRadius='8px' backgroundColor='#202020' p={2} px={4}>
                        +
                      </Box> */}
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    {/* <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      border='1px solid #474747'
                      borderRadius='8px'
                      backgroundColor='#202020'
                      p={2}
                      gap={4}
                    > */}
                    {/* <img src={CalendarIcon} alt='CalendarIcon' style={{ height: '24px', width: '24px' }} /> */}
                    <FormControl fullWidth>
                      <Controller
                        name='date'
                        control={control}
                        render={({ field }) => (
                          <>
                            {/* <TextField
                              fullWidth
                              // disabled
                              autoComplete={false}
                              error={Boolean(errors.scheduledDate)}
                              id='scheduledDate'
                              placeholder={translation.translate('form.selectDate.placeholder')}
                              value='Instant'
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <img src={CalendarIcon} alt='Calendar' style={{ height: '24px', width: '24px' }} />
                                  </InputAdornment>
                                )
                              }}
                            /> */}
                            <FormControl fullWidth size='small'>
                              <div style={{ position: 'relative', zIndex: 1 }}>
                                <CustomDatePicker
                                  onDateChange={date => field.onChange(date)}
                                  onChange={date => field.onChange(date)}
                                  value={new Date()}
                                  type='instant'
                                />
                              </div>
                            </FormControl>
                          </>
                        )}
                      />
                      {errors.date && (
                        <FormHelperText sx={{ color: 'error.main' }}>
                          <Translations text={`${errors.date.message}`} />
                        </FormHelperText>
                      )}
                    </FormControl>
                    {/* <Typography variant='fm-p3' color='common.white' fontWeight={500}>
                        <Translations text='Instant' />
                      </Typography>
                    </Box> */}
                  </Grid>
                </Grid>

                <Box display='flex' flexDirection='column' gap={4}>
                  <Typography variant='fm-h5' color='common.white' fontWeight={500}>
                    <Translations text='Select Payment Method' />
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <Controller
                      name='paymentMethod'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          sx={{
                            width: '100%'
                          }}
                          id='paymentMethod'
                          options={['Wallet', 'Cash', 'Card']}
                          defaultValue='Cash'
                          // onChange={onChange}
                          onChange={(event, newValue) => setValue('paymentMethod', newValue)}
                          // getOptionLabel={option => option}
                          renderInput={params => (
                            <TextField
                              error={Boolean(errors.paymentMethod)}
                              placeholder={translation.translate('form.itemType.placeholder')}
                              renderOption={(props, option) => (
                                <Box component='li' {...props}>
                                  <Translations text={option} />
                                </Box>
                              )}
                              {...params}
                              inputProps={{
                                ...params.inputProps
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors.paymentMethod && (
                      <FormHelperText sx={{ color: 'error.main' }}>
                        <Translations text={`${errors.paymentMethod.message}`} />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                {/* {!drivers.length > 0 && ( */}
                <LoadingButton
                  fullWidth
                  loading={loading}
                  size='large'
                  type='submit'
                  variant='contained'
                  sx={{
                    mt: 4,
                    borderRadius: '8px',
                    height: '55px',
                    fontWeight: 600,
                    fontSize: '16px',
                    backgroundColor: 'common.white'
                  }}
                >
                  <Translations text='Search for Rides' />
                  {/* <ArrowIcon /> */}
                </LoadingButton>
                {/* )} */}
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box sx={{ borderRadius: '10px' }}>
            {/* <iframe
              title='Google Map'
              src={`https://maps.google.com/maps/?q=20.609961,72.930656&output=embed`}
              // width='600'
              height='755'
              style={{ border: 0, width: '100%', borderRadius: '10px' }}
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe> */}

            {coords && (
              <APIProvider apiKey='AIzaSyBydxmR64ODpWEtho3aPfVb-f6yvSy7uxY'>
                <Map
                  style={{ width: 'auto', height: 698, border: '1px solid #E6E6E6', borderRadius: '10px' }}
                  styles={mapStyle}
                  mapTypeId='roadmap'
                  defaultCenter={{ lat: coords?.latitude, lng: coords?.longitude }}
                  defaultZoom={15}
                  // minZoom={1000}
                  gestureHandling={'greedy'}
                  disableDefaultUI={true}
                  // mapId='f90f8309a7a49925'
                  options={{
                    minZoom: 500,
                    maxZoom: 15
                    // styles: mapStyle
                  }}
                >
                  {/* <Marker
                    key='pickUpMarker'
                    shape='circle'
                    position={{ lat: coords?.latitude, lng: coords?.longitude }}
                  >
                    <img src={CurrentLocationIcon} alt='Custom Marker' />
                  </Marker> */}
                  <CustomMarker
                    origin={{ lat: coords.latitude, lng: coords.longitude }}
                    destination={{ lat: coords.latitude, lng: coords.longitude }}
                    type='circle'
                  />
                  {drivers.length > 0 &&
                    drivers.map((driver, index) => (
                      <CustomMarker
                        origin={{ lat: parseFloat(driver?.latitude), lng: parseFloat(driver?.longitude) }}
                        destination={{ lat: parseFloat(driver?.latitude), lng: parseFloat(driver?.longitude) }}
                        type='car'
                      />
                    ))}
                </Map>
              </APIProvider>
            )}
            {!coords && (
              <APIProvider apiKey='AIzaSyBydxmR64ODpWEtho3aPfVb-f6yvSy7uxY'>
                <Map
                  style={{ width: 'auto', height: 698, border: '1px solid #E6E6E6', borderRadius: '10px' }}
                  styles={mapStyle}
                  mapTypeId='roadmap'
                  defaultCenter={
                    user.country_code === '+91' ? { lat: 20.5937, lng: 78.9629 } : { lat: 9.082, lng: 8.6753 }
                  }
                  defaultZoom={6}
                  gestureHandling={'greedy'}
                  disableDefaultUI={true}
                  options={{
                    minZoom: 500,
                    maxZoom: 15
                  }}
                ></Map>
              </APIProvider>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default BookRidePage
