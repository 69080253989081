import React from 'react'

const Message = ({ notification }) => {
  console.log('notificationssssss', notification)
  return (
    <div>
      <div id='notificationHeader'>
        {notification.image && (
          <div id='imageContainer'>
            <img src={notification.image} width={100} alt='Notification' />
          </div>
        )}
        <span>{notification.title}</span>
      </div>
      <div id='notificationBody'>{notification.body}</div>
    </div>
  )
}

export default Message
