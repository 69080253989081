import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { TextField } from '@mui/material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import InputAdornment from '@mui/material/InputAdornment'
import { format } from 'date-fns' // Import the date-fns library for date formatting
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
import CalendarIcon from 'src/assets/images/calendar.png'
import Translations from 'src/layouts/components/Translations'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'

const Datepicker = ({ value, onChange, onDateChange, error, type }) => {
  const [dates, setDates] = useState(new Date(value))
  const [dateType, setDateType] = useState(type)
  const translation = useCustomTranslation()
  const handleDateChange = date => {
    setDateType('change')
    onDateChange(date)
    onChange(date)
    setDates(date)
  }

  const formatDate = date => {
    // Convert the date to the desired format
    return date ? format(date, 'dd/MM/yyyy') : ''
  }

  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 30)

  useEffect(() => {
    setDates(dates ? dates : new Date(value))
    setDateType(dateType ? dateType : type)
  }, [value, type])

  return (
    <>
      <DatePickerWrapper>
        <div style={{ position: 'relative' }}>
          <DatePicker
            // disabled
            selected={dateType === 'instant' ? '' : dates}
            onChange={handleDateChange}
            // portalId='root-portal'
            dateFormat='MMMM d, yyyy h:mm aa'
            peekNextMonth
            showMonthDropdown
            minDate={new Date()}
            maxDate={maxDate}
            popperClassName='custom-datepicker-popper'
            // popperPlacement='top-end'
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 10] // Adjust the offset if needed
                }
              },
              {
                name: 'preventOverflow',
                options: {
                  boundary: 'viewport'
                }
              }
            ]}
            showYearDropdown
            showTimeSelect
            timeFormat='HH:mm'
            timeIntervals={15}
            // dropdownMode="select"
            style={{ width: '100%' }}
            placeholderText={translation.translate('Instant')}
            customInput={
              <TextField
                error={error && !dates} // Set error prop based on the condition
                fullWidth
                variant='outlined'
                placeholder='DD/MM/YYYY'
                value={formatDate(dates)} // Set the formatted value here
                InputProps={{
                  style: {
                    border: dateType === 'ridePage' ? '1px solid #fff' : 'none',
                    borderRadius: '10px'
                  },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={CalendarIcon} alt='Calendar' style={{ height: '24px', width: '24px' }} />
                    </InputAdornment>
                  )
                }}
              />
            }
          />
        </div>
      </DatePickerWrapper>
    </>
  )
}

export default Datepicker
