// ** Icon imports
import Home from 'src/assets/images/navbar-home.png'
import Order from 'src/assets/images/navbar-order.png'
import Profile from 'src/assets/images/navbar-profile.png'

const navigation = () => {
  return [
    {
      title: 'Home',
      icon: Home,
      path: '/home'
    },
    {
      title: 'My Order',
      icon: Order,
      path: '/order'
    },
    {
      title: 'My Profile',
      icon: Profile,
      path: '/profile'
    },
    {
      title: 'Settings',
      icon: Profile,
      path: '/profile/settings'
    }
  ]
}

export default navigation
