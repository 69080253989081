import { Box, Button, ButtonBase, Card, CardContent, Grid, Typography } from '@mui/material'
import Translations from 'src/layouts/components/Translations'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import AlertIcon from 'src/assets/images/alert.svg'
import EmergencyContactList from 'src/views/pages/security/emergencyContactList'
import EmergencyContactForm from 'src/views/pages/security/emergencyContactForm'
import EmergencyContactVerify from 'src/views/pages/security/emergencyContactVerify'
import PayNowAlert from 'src/views/pages/common/payNowAlert'
import { useAuth } from 'src/hooks/useAuth'

function SecurityPage() {
  const [operationMode, setOperationMode] = useState('add')
  const navigate = useNavigate()
  const { user } = useAuth()

  const [activeTab, setActiveTab] = useState(0)
  const handleOperationChange = (index, mode) => {
    setActiveTab(index)
    setOperationMode(mode)
  }
  const [dataToVerify, setDataToVerify] = useState({})

  const handleDataToVerify = data => {
    setDataToVerify(data)
  }

  return (
    <>
      {user?.ongoingRides?.status && <PayNowAlert />}
      <Grid container display='flex' spacing={5} px={{ md: '113px', xs: '10px' }} flexDirection='row'>
        <Grid item xs={12} lg={4} display='flex' gap={5} flexDirection='column'>
          <Card sx={{ bgcolor: '#111111', border: '1px solid #202020' }}>
            <CardContent>
              <Box display='flex' gap={7} flexDirection='column'>
                <Box display='flex' alignItems='center' gap={4}>
                  <Box
                    component={ButtonBase}
                    onClick={() => navigate('/profile')}
                    border='1px solid #474747'
                    borderRadius='8px'
                    bgcolor='#202020'
                    display='flex'
                    p={3}
                  >
                    <ArrowLeft />
                  </Box>
                  <Typography variant='fm-h5' color='common.white' fontWeight={500}>
                    <Translations text='Security' />
                  </Typography>
                </Box>
                <Box display='flex' gap={2} flexDirection='column'>
                  <div>
                    <Button
                      variant='text'
                      endIcon={<ArrowForwardIosIcon />}
                      sx={{
                        padding: '15px',
                        borderRadius: '10px',
                        height: '50px',
                        width: '100%',
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 400,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 1,
                        minWidth: 0,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      onClick={() => handleOperationChange(0, 'add')}
                    >
                      <Box display='flex' gap={2}>
                        <img src={AlertIcon} alt='' />
                        <Translations text='Emergency Contact' />
                      </Box>
                    </Button>
                  </div>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={8}>
          {activeTab === 0 ? (
            <EmergencyContactList
              operationChange={(index, operationMode) => handleOperationChange(index, operationMode)}
            />
          ) : null}
          {activeTab === 1 ? (
            <EmergencyContactForm
              operationMode={operationMode}
              dataToVerify={dataToVerify => handleDataToVerify(dataToVerify)}
              operationChange={(index, operationMode) => handleOperationChange(index, operationMode)}
            />
          ) : null}
          {activeTab === 2 ? (
            <EmergencyContactVerify
              dataToVerify={dataToVerify}
              operationChange={(index, operationMode) => handleOperationChange(index, operationMode)}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  )
}

export default SecurityPage
