import React, { useEffect } from 'react'
import { useState } from 'react'
// import { yupResolver } from '@hookform/resolvers/yup'
import { Box, ButtonBase, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import { ApiEndpoints } from 'src/network/endpoints'
import { axiosInstance } from 'src/network/adapter'
import { LoadingButton } from '@mui/lab'
import Alert from 'src/assets/images/alert.svg'
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import Translations from 'src/layouts/components/Translations'
import toast from 'react-hot-toast'
import DeleteIcon from '@mui/icons-material/Delete'

function EmergencyContactList(props) {
  const [loading, setLoading] = useState(false)
  const { operationChange } = props
  const [emergencyContact, setEmergencyContact] = useState([])

  const getEmergencyContact = () => {
    setLoading(true)
    axiosInstance
      .get(ApiEndpoints.EMERGENCY_CONTACT.emergency_contact)
      .then(response => {
        setLoading(false)
        setEmergencyContact(response.data.data.contact)
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
  }

  const deleteContanct = id => {
    // setLoading(true)
    axiosInstance
      .delete(ApiEndpoints.EMERGENCY_CONTACT.delete_emergency_contact(id))
      .then(response => {
        toast.success(response.data.message)
        // setLoading(false)
        getEmergencyContact()
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
  }

  useEffect(() => {
    getEmergencyContact()
  }, [])

  return (
    <Card>
      {/* <CardHeader
        titleTypographyProps={{ style: { fontSize: '22px', fontWeight: 600, color: '#fff' } }}
        title='Address'
      /> */}
      {/* <CardHeader
        sx={{ pb: 7 }}
        titleTypographyProps={{ style: { fontSize: '22px', fontWeight: 600, color: '#fff' } }}
        title='Emergency'
      /> */}
      <CardContent>
        <Box display='flex' alignItems='center' gap={4} mb={5}>
          <Box
            component={ButtonBase}
            // onClick={() => navigate('/profile')}
            border='1px solid #474747'
            borderRadius='8px'
            bgcolor='#202020'
            display='flex'
            p={3}
          >
            <ArrowLeft />
          </Box>
          <Typography fontSize='22px' color='common.white' fontWeight={600}>
            <Translations text='Emergency' />
          </Typography>
        </Box>
        <Box border='1px solid #474747' borderRadius='8px' bgcolor='#202020' p={5}>
          <Grid container direction='column' spacing={4}>
            <Grid item container spacing={6} xs={12}>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography sx={{ fontSize: '16px' }} color='common.white' fontWeight={400}>
                    <Translations text='Add Emergency contact' />
                  </Typography>
                  <img src={Alert} alt='Alert' />
                </Box>
              </Grid>
            </Grid>
            <Grid item spacing={6} xs={12}>
              {emergencyContact.length > 0 ? (
                emergencyContact.map((item, index) => (
                  <Box display='flex' alignItems='center' justifyContent='space-between' gap={3} mb={4}>
                    <Box display='flex' alignItems='center' gap={3} mb={4}>
                      <IconButton
                        sx={{
                          borderRadius: '50%',
                          backgroundColor: '#0E0E0E',
                          width: '56px',
                          height: '56px'
                        }}
                      >
                        <img
                          style={{ width: '30px', height: '30px' }}
                          src={Alert}
                          // src={vehicle}
                          alt='vehicle'
                        />
                      </IconButton>
                      <Box display='flex' flexDirection='column'>
                        <Typography variant='fm-p2' color='common.white' fontWeight={600}>
                          <Translations text={item.contact_name} />
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }} color='#868588'>
                          <Translations text={item.phone_number} />
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton onClick={() => deleteContanct(item.id)}>
                      <DeleteIcon sx={{ color: '#fff !important' }} color='common.white' />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Box display='flex' justifyContent='center'>
                  <Typography sx={{ fontSize: '12px' }} color='neutral.70' fontWeight={400}>
                    <Translations text='No emergency contact added yet!' />
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <LoadingButton
          sx={{
            mt: 5,
            textTransform: 'none',
            width: '180px',
            height: '50px',
            borderRadius: '8px',
            fontSize: '16px',
            fontWeight: 600,
            background: 'primary'
          }}
          type='submit'
          variant='contained'
          loading={loading}
          onClick={() => operationChange(1, 'add')}
        >
          <Translations text='Add' />
        </LoadingButton>
      </CardContent>
    </Card>
  )
}

export default EmergencyContactList
