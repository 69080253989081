import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { Box, Card, CardContent, CardHeader, InputBase, IconButton, Typography, Snackbar, Grid } from '@mui/material'
import { useAuth } from 'src/hooks/useAuth'
import copy from 'copy-to-clipboard'
import CopyIcon from 'src/assets/images/copy-icon.svg'
import ReferFriendImage from 'src/assets/images/refer-friend.png'
import Translations from 'src/layouts/components/Translations'
import { LoadingButton } from '@mui/lab'
import WarningIcon from '@mui/icons-material/Warning'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'

function PayNowAlert() {
  const [loading, setLoading] = useState(false)
  const [otpVerfied, setOtpVerfied] = useState('')
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  console.log('user', user)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  const onSubmit = useCallback(data => {}, [])
  const invitationLink = user.refer_friends_with

  const handleCopyToClipboard = () => {
    copy(invitationLink)
    setSnackbarOpen(true)
  }
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const getRides = () => {
    axiosInstance.get(ApiEndpoints.RIDES.get_rides(user.ongoingRides.ride)).then(response => {
      console.log('response', response)
      setOtpVerfied(response.data.data.rides.otpVerfied)
      // setRideId(response.data.data.rides.id)
      // setRides(response.data.data.rides)
    })
  }

  useEffect(() => {
    getRides()
  }, [])

  return (
    <Grid
      container
      display='flex'
      px={{ md: '113px', xs: '10px' }}
      // sx={{ zIndex: 0 }}
      flexDirection='row'
      mb={3}
    >
      <Grid item sx={{ width: '100%' }} xs={12} lg={12} display='flex' flexDirection='column'>
        <Card
          sx={{
            width: '100%',
            bgcolor: '#D05C5C',
            border: '1px solid #D05C5C',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
          }}
        >
          <CardContent>
            {/* <Box display='flex' justifyContent='space-between' alignItems='center'> */}
            <Box display='flex' alignItems='center' justifyContent='space-between' gap={2}>
              <Box alignItems='center' display='flex' gap={2}>
                <WarningIcon />
                <Typography variant='fm-p1' color='common.white' fontWeight={400}>
                  <Translations text='Please be advised that you have an active ride in progress. To ensure a smooth experience, we kindly request that you complete your current ride ' />
                </Typography>
              </Box>
              <LoadingButton
                loading={loading}
                onClick={() =>
                  navigate(otpVerfied ? `/pay-now/${user.ongoingRides.ride}` : `/find-driver/${user.ongoingRides.ride}`)
                }
                size='large'
                type='submit'
                variant='contained'
                sx={{
                  borderRadius: '8px',
                  height: '40px',
                  fontWeight: 600,
                  fontSize: '16px',
                  backgroundColor: 'common.white',
                  color: '#D05C5C',
                  minWidth: '150px',
                  '&:hover': {
                    backgroundColor: 'common.white'
                  }
                }}
              >
                {' '}
                <Translations text={otpVerfied ? 'Pay Now' : 'Resume Ride'} />
              </LoadingButton>
            </Box>
            {/* </Box> */}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PayNowAlert
