import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'
import { Box, ButtonBase, Card, CardContent, FormHelperText, Grid, Snackbar, Typography } from '@mui/material'
import Translations from 'src/layouts/components/Translations'
import 'react-phone-input-2/lib/bootstrap.css'
import { ApiEndpoints } from 'src/network/endpoints'
import { FormValidationMessages } from 'src/constants/form.const'
import * as yup from 'yup'
import { axiosInstance } from 'src/network/adapter'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'
import Alert from 'src/assets/images/alert.svg'
import { MuiOtpInput } from 'mui-one-time-password-input'
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'

function EmergencyContactVerify(props) {
  const [loading, setLoading] = useState(false)
  const { dataToVerify, operationChange } = props
  const [otp, setOtp] = useState('')
  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .required(FormValidationMessages.OTP.required)
      .matches(FormValidationMessages.OTP.pattern, FormValidationMessages.OTP.patternErrorMessage)
  })
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  })

  const handleOtpVerify = async e => {
    setLoading(true)
    let payload = {
      otp: otp,
      phone_number: dataToVerify.phone_number,
      country_code: dataToVerify.country_code
    }
    axiosInstance
      .post(ApiEndpoints.EMERGENCY_CONTACT.verify_otp, payload)
      .then(response => {
        toast.success(response.message)
        setOpen(prev => !prev)
        operationChange(0, 'add')
      })
      .catch(error => {
        toast.error(error.response.data.message)
        console.log(error)
      })
    setLoading(false)
  }
  const handleOtpChange = newValue => {
    setOtp(newValue)
  }
  const [open, setOpen] = useState(false)

  return (
    <Card>
      <CardContent>
        <Box display='flex' alignItems='center' gap={4} mb={5}>
          <Box
            component={ButtonBase}
            // onClick={() => navigate('/profile')}
            onClick={() => operationChange(1, 'add')}
            border='1px solid #474747'
            borderRadius='8px'
            bgcolor='#202020'
            display='flex'
            p={3}
          >
            <ArrowLeft />
          </Box>
          <Typography fontSize='22px' color='common.white' fontWeight={600}>
            <Translations text='Emergency' />
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(handleOtpVerify)}>
          <Box border='1px solid #474747' borderRadius='8px' bgcolor='#202020' p={5}>
            <Grid container direction='column' spacing={4}>
              <Grid item container spacing={6} xs={12}>
                <Grid item xs={12} sm={12}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography sx={{ fontSize: '16px' }} color='common.white' fontWeight={400}>
                      <Translations text='Verify Emergency contact' />
                    </Typography>
                    <img src={Alert} alt='Alert' />
                  </Box>
                </Grid>
              </Grid>
              <Grid item spacing={6} xs={12}>
                <Typography color='netral.80' fontSize='12px'>
                  Please Enter 4 digit code sent to
                </Typography>
                <Typography color='primary' fontSize='12px'>
                  {dataToVerify.phone_number}
                </Typography>
                <Controller
                  name='otp'
                  control={control}
                  defaultValue=''
                  render={({ field, fieldState }) => (
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      width={{ md: '380px', xs: '350px' }}
                      px={7}
                    >
                      <MuiOtpInput
                        {...field}
                        value={otp}
                        onChange={newValue => {
                          handleOtpChange(newValue)
                          field.onChange(newValue)
                        }}
                        style={{ display: 'flex', justifyContent: 'center', marginTop: 5, width: '100%' }}
                      />
                      {fieldState.invalid ? <FormHelperText error>{fieldState.error?.message}</FormHelperText> : null}
                    </Box>
                  )}
                />
                {open && (
                  <Snackbar open={open} autoHideDuration={2000}>
                    <Alert severity='success' sx={{ width: '100%', color: 'fff' }}>
                      OTP verified Successfully
                    </Alert>
                  </Snackbar>
                )}
              </Grid>
            </Grid>
          </Box>
          <LoadingButton
            sx={{
              mt: 5,
              textTransform: 'none',
              width: '180px',
              height: '50px',
              borderRadius: '8px',
              fontSize: '16px',
              fontWeight: 600,
              background: 'primary'
            }}
            type='submit'
            variant='contained'
            loading={loading}
          >
            <Translations text='Verify' />
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  )
}

export default EmergencyContactVerify
