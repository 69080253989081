import { LoadingButton } from '@mui/lab'
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  FormHelperText,
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem
} from '@mui/material'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import Translations from 'src/layouts/components/Translations'
import { FormValidationMessages } from 'src/constants/form.const'
import Cancel from 'src/assets/images/cancel.svg'
import { CleaveNumberInput } from 'src/@core/components/cleave-component'
import { useFlutterwave } from 'flutterwave-react-v3'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import toast from 'react-hot-toast'
import { useAuth } from 'src/hooks/useAuth'

const validationSchema = yup.object().shape({
  fund_amount: yup
    .number()
    .typeError(FormValidationMessages.FUND_AMOUNT.required)
    .required(FormValidationMessages.FUND_AMOUNT.required)
})

function FundWalletDialog(props) {
  const translation = useCustomTranslation()
  const [loading, setLoading] = useState(false)
  const { open, toggle } = props
  const [selectedCurrency, setSelectedCurrency] = useState('₦')
  const [amount, setAmount] = useState(0)
  const navigate = useNavigate()
  const { user } = useAuth()

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const onSubmit = async data => {
    setValue('fund_amount', amount)
    handleFlutterPayment({
      callback: response => {
        const { status, transaction_id } = response
        if (status === 'successful') {
          verifyTransaction(transaction_id)
          navigate('/home')
        } else {
          navigate('/home')
        }
      }
    })
    toggle()
  }

  const changeAmount = amount => {
    setAmount(amount)
    setValue('fund_amount', amount)
  }

  const handleClose = () => {
    toggle()
    setValue('fund_amount', '')
    clearErrors('fund_amount')
  }

  const config = {
    public_key: 'FLWPUBK-c0e39d99ac1395e08c4c6973c7bc7502-X',
    tx_ref: Date.now().toString(),
    amount: amount,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: user.email,
      phone_number: user.phone_number,
      name: `${user.first_name} ${user.last_name}`
    },
    customizations: {
      title: 'Wallet payment',
      description: 'Payment for items in cart',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg'
    }
  }

  const handleFlutterPayment = useFlutterwave(config)

  const verifyTransaction = transactionId => {
    axiosInstance
      .post(ApiEndpoints.PAYMENT.wallet, {
        transactionId: transactionId?.toString()
      })
      .then(response => {
        toast.success('Payment successful')
        navigate(`/profile`)
        window.location.reload()
      })
      .catch(error => {
        toast.error(error.message)
        console.log(error)
        navigate(`/profile`)
        window.location.reload()
      })
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xs'
        sx={{ '& .MuiPaper-root': { borderRadius: '30px' } }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box display='flex' alignItems='center' justifyContent='space-between' mb={3}>
            <Typography sx={{ fontSize: '24px', fontWeight: 600 }} color='common.white'>
              {/* {translation.translate('form.fundAmount.label')} */}
              Amount
            </Typography>
            <IconButton
              aria-label='close'
              onClick={toggle}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500]
              }}
            >
              {/* <CloseIcon /> */}
              <img src={Cancel} style={{ height: '46px', width: '46px' }} alt='cancel' />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display='flex' flexDirection='column' justifyContent='space-between'>
            <Box>
              <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <Grid container sx={{ display: 'flex', justifyContent: 'center' }} gap={3}>
                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <FormControl fullWidth sx={{ mb: 4 }}>
                      {/* <Controller
                        name='fund_amount'
                        control={control}
                        defaultValue={false}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            type='text'
                            // inputMode='numeric'
                            // type='number'
                            error={Boolean(errors.full_name)}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              // inputComponent: CleaveNumberInput,
                              endAdornment: <InputAdornment position='end'>₦</InputAdornment>
                            }}
                            value={value}
                            onChange={onChange}
                            id='fund_amount'
                            placeholder={translation.translate('form.fundAmount.placeholder')}
                          />
                        )}
                      /> */}
                      <Controller
                        name='fund_amount'
                        control={control}
                        rules={{ required: true, pattern: /^[0-9]*$/ }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            type='text'
                            inputMode='numeric'
                            value={value}
                            autoComplete='off'
                            onChange={(e, newValue) => changeAmount(newValue)}
                            error={Boolean(errors.fund_amount)}
                            placeholder={translation.translate('form.fundAmount.placeholder')}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              inputComponent: CleaveNumberInput,
                              endAdornment: (
                                <InputAdornment position='end' sx={{ width: '50px' }}>
                                  <Box sx={{ width: '50px' }}>
                                    <Select
                                      // as={StyledSelect}
                                      sx={{ width: '60px', '& fieldset': { border: 'none' } }}
                                      // variant='standard'
                                      value={selectedCurrency}
                                      onChange={e => setSelectedCurrency(e.target.value)}
                                    >
                                      <MenuItem sx={{ width: '50px' }} value='₦'>
                                        ₦
                                      </MenuItem>
                                      <MenuItem sx={{ width: '50px' }} value='₹'>
                                        ₹
                                      </MenuItem>
                                      <MenuItem sx={{ width: '50px' }} value='$'>
                                        $
                                      </MenuItem>
                                    </Select>
                                  </Box>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                      {errors.fund_amount && (
                        <FormHelperText sx={{ color: 'error.main' }}>
                          <Translations text={`${errors.fund_amount.message}`} />
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <LoadingButton
                    fullWidth
                    // onClick={() => {

                    // }}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      fontWeight: 600,
                      background: 'primary'
                    }}
                    type='submit'
                    variant='contained'
                    loading={loading}
                    // disabled={loading}
                  >
                    <Translations text='Fund' />
                    {/* {loading ? 'Submitting...' : 'Save'} */}
                  </LoadingButton>
                </Grid>
              </form>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default FundWalletDialog
