export const FormValidationMessages = {
  EMAIL: {
    required: 'Email is required',
    // pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.com)?$/,
    invalid: 'Must be a valid email address'
  },
  PASSWORD: {
    required: 'Password is required',
    minLength: 8,
    minLengthErrorMessage:
      'Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase',
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    patternErrorMessage:
      'Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase'
  },
  FIRST_NAME: {
    required: 'First name is required'
  },
  MIDDLE_NAME: {
    required: 'Middle name is required'
  },
  LAST_NAME: {
    required: 'Last name is required'
  },
  FULL_NAME: {
    required: 'Name is required'
  },
  PROFILE_PICTURE: {
    required: 'Profile picture is required'
  },
  BUSINESS_NAME: {
    required: 'Name of Business is required'
  },
  ADDRESS: {
    required: 'Address is required'
  },
  ADDRESS_TYPE: {
    required: 'Address type is required'
  },
  PINCODE: {
    required: 'Pincode is required'
  },
  CITY_NAME: {
    required: 'City Name is required'
  },
  STATE: {
    required: 'State is required'
  },
  COUNTRY: {
    required: 'Country is required'
  },
  MOBILE_NUMBER: {
    required: 'Mobile number is required'
  },
  DOB: {
    required: 'Date of Birth is required'
  },
  IS_USER_CONSENT: {
    required: 'User Consent is required'
  },
  ACCEPT_TC: {
    required: 'Please Accept all Terms & Conditions'
  },
  PHONE: {
    required: 'Phone number is required',
    pattern: /^[0-9]+$/,
    patternErrorMessage: 'Must contain only numeric digits'
  },
  OTP: {
    required: 'OTP is required',
    pattern: /^[0-9]+$/,
    patternErrorMessage: 'Must contain only numeric digits'
  },
  MPIN: {
    required: 'MPIN is required',
    pattern: /^[0-9]+$/,
    patternErrorMessage: 'Must contain only numeric digits'
  },
  RE_MPIN: {
    required: 'MPIN is required',
    pattern: /^[0-9]+$/,
    patternErrorMessage: 'Must contain only numeric digits'
  },
  FUND_AMOUNT: {
    required: 'Fund amount is required'
  },
  SCHEDULE_DATE: {
    required: 'Schedule date is required'
  },
  SCHEDULE_TIME: {
    required: 'Schedule time is required'
  },
  ITEM_TYPE: {
    required: 'Item type is required'
  },
  DESCRIBE_ITEM: {
    required: 'Item description is required'
  },
  WEIGHT_OF_ITEM: {
    required: 'Weight of item is required'
  },
  PERSON_NAME: {
    required: 'Person name is required'
  },
  PERSON_NUMBER: {
    required: 'Person number is required'
  },
  PINCODE: {
    required: 'Pincode is required'
  },
  CITY: {
    required: 'City is required'
  },
  ADDRESS: {
    required: 'Address is required'
  },
  PICKUP_POINT: {
    required: 'Pickup point is required'
  },
  BUSINESS_NAME: {
    required: 'Business Name is required'
  },
  BUSINESS_CATEGORY: {
    required: 'Business category is required'
  },
  BUSINESS_PRODUCT_CATEGORY: {
    required: 'Product category is required'
  },
  BUSINESS_COUNTRY: {
    required: 'Business country is required'
  },
  BUSINESS_ADDRESS: {
    required: 'Business Address is required'
  },
  REGISTRATION_NUMBER: {
    required: 'Registration Number is required'
  },
  ORIGIN: {
    required: 'Origin is required'
  },
  DESTINATION: {
    required: 'Destination is required'
  },
  USER: {
    required: 'User is required'
  },
  FUND_AMOUNT: {
    required: 'Fund amount is required'
  },
  TEXT: {
    required: 'Text is required'
  }
}
